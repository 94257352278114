<template>
    <b-card>
        <div
              id="starter-print"
              class="d-table-starter"
            >
              <!-- <DxDataGrid
                :ref="dataGridRef"
                :height="600"
                class="table-starter"
                :show-borders="false"
                :data-source="listMember"
                :show-column-lines="true"
                :show-row-lines="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                column-resizing-mode="widget"
                :column-auto-width="true"
                @cellClick="cellClick"
                @row-updating="rowUpdating"
                @rowPrepared="cssRowStarter"
                @initialized="saveGridInstance"
              > -->
              <b-overlay
                :opacity="0.3"
                rounded="sm"
                :show="isLoading"
              >
              <DxDataGrid
                :ref="dataGridRef"
                :height="600"
                class="table-starter"
                :show-borders="false"
                :data-source="listMember"
                :show-column-lines="true"
                :show-row-lines="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                column-resizing-mode="widget"
              >
                <DxColumn
                  v-for="(item, i) in configTable"
                  :key="i.field"
                  :caption="item.caption"
                  :data-field="item.field"
                  :data-type="item.type"
                  :cell-template="(item.cellTemplate) ? item.cellTemplate : false"
                  :alignment="item.alignment"
                  :allow-editing="item.allowEditing"
                  :edit-cell-template="item.editCellTemplate"
                  :editor-options="item.editorOptions"
                  :allow-filtering="item.allowFiltering"
                  :width="item.width"
                >
                <DxLookup
                    v-if="item.field == 'SexCode'"
                    :data-source="listSexCode"
                    value-expr="KeyCode"
                    display-expr="KeyValue"
                  />
                  <DxColumn
                    v-for="(band, j) in item.bands"
                    v-if="band.isShow && item.bands.length > 0"
                    :key="band.field"
                    :caption="band.caption"
                    :data-field="band.field"
                    :data-type="band.type"
                    :cell-template="(band.cellTemplate) ? band.cellTemplate : false"
                    :alignment="band.alignment"
                    :allow-editing="item.allowEditing"
                    :edit-cell-template="item.editCellTemplate"
                    :width="item.width"
                  />
                </DxColumn>
                
                <!-- <DxFilterRow :visible="true" /> -->
                <!-- Tuỳ chỉnh hiển thị rate name -->
                <template #full-name="{ data }">
                  <div class="text-truncate">
                    {{ data.data.FullName }}
                  </div>
                </template>

                <!-- Tuỳ chỉnh hiển thị golf class -->
                <template #sex-code="{ data }">
                  <div v-if="data.data.SexCode" class="text-truncate">
                    {{ listSexCode.find(x => x.KeyCode == data.data.SexCode) ? listSexCode.find(x => x.KeyCode == data.data.SexCode).KeyValue : '' }}
                  </div>
                </template>

                <!-- Tuỳ chỉnh hiển thị golf class -->
                <template #caddy="{ data }">
                  <div>
                    <div v-if="data.data.CaddyCode">
                      <ez-icon
                        v-if="data.data.BookingDetailCaddy.find(x => x.CaddyId === data.data.CaddyId) ? data.data.BookingDetailCaddy.find(x => x.CaddyId === data.data.CaddyId).IsBooked : false"
                        v-b-tooltip.hover
                        icon="ezGolf-icon-wallet"
                        size="14"
                        :title="$t('golf_is_booked_caddy')"
                      />
                      {{ data.data.CaddyCode }}
                    </div>
                  </div>
                </template>

                <template #BookingDetailCourse="{ data }">
                  <div>
                    <span
                      v-for="course in data.data.BookingDetailCourse"
                      v-if="listCourse.filter( x => x.Id == course.CourseId ).length"
                      :key="course.CourseId"
                      class="text-truncate"
                    >
                      <ez-icon
                        icon="ezGolf-icon-golf-course"
                        :color="listCourse.find( x => x.Id == course.CourseId ).Color"
                        :size="16"
                      />
                    </span>
                  </div>
                </template>

                <template #buggy-code="{ data }">
                  <b-form-group>
                    <b-form-input
                      v-model="data.data.BuggyCode"
                    />
                  </b-form-group>
                </template>

                
                <template #birth-day="{ data }">
                  <div class="ez-hide-date">
                    {{ convertUTC(data.data.BirthDay, 'DD/MM/YYYY') }}
                  </div>
                </template>

                <!-- Tuỳ chỉnh hiển thị action -->
                <template #action="{ data }">
                  <b-nav>
                    <b-nav-item-dropdown
                      id="dot-more-action"
                      right
                    >
                      <template slot="button-content">
                        <feather-icon
                          icon="MoreVerticalIcon"
                          color="#114A9F"
                        />
                      </template>
                      <b-dropdown-item>
                        <div
                          type="button"
                          @click="openUpdateMember(data.data)"
                        >
                          {{ $t("golf_common_view_detail" ) }}
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <div
                          style="color: #EA5455"
                          type="button"
                          @click="DeleteMember(data.data.Id)"
                        >
                          {{ $t('golf_tour_delete_member') }}
                        </div>
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                  </b-nav>

                </template>

                <DxToolbar>
                  <DxItem
                    location="before"
                    template="beforeTemplate"
                  />
                  <DxItem
                    location="after"
                    template="afterTemplate"
                  />
                  <DxItem
                    widget="dxButton"
                    location="after"
                    :options="{width: 70}"
                    name="revertButton"
                  />
                  <DxItem
                    widget="dxButton"
                    location="after"
                    :options="{width: 70}"
                    name="saveButton"
                    cssClass='border-button'
                  />
                </DxToolbar>
                <template #beforeTemplate>
                  <div>
                    <div class="d-select-perPage">
                      <b-form-group
                        :label="$t('golf_starter_result_on_page')"
                        label-cols-md="7"
                      >
                        <v-select
                          v-model="perPage"
                          :options="optionPerPage"
                          :clearable="false"
                          style="min-width: 75px"
                        />
                      </b-form-group>
                    </div>

                  </div>
                </template>
                <template #afterTemplate>
                  <div>
                    <input type="file" class="form-import-excel__field-choosefile d-none" ref="choosefileExcel" @input="readFileExcel"/>
                    <b-dropdown
                        variant="outline-primary"
                        class="mr-2"
                        style="height:100%"
                      >
                        <template #button-content>
                          <span class="align-middle">{{ $t('golf_common_import_excel') }}</span>
                        </template>
                        <b-dropdown-item @click="triggerClickChooFiles()">
                          {{ $t('golf_common_import_excel') }}
                        </b-dropdown-item>
                        <!-- <b-dropdown-item @click="generateXLSX()">
                          {{ $t('golf_common_template_import_to_excel') }}
                        </b-dropdown-item> -->
                      </b-dropdown>
                      <b-button
                      variant="outline-primary"
                      class="mr-1"
                      @click="sysMemberTour"
                    >
                    {{ $t('golf_tour_sys_member_tour') }}
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                      @click="openModalScanGolfer"
                    >
                    {{ $t('golf_tour_search_golfer') }}
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                      @click="openModalAddMember"
                    >
                    <span class="align-middle d-flex align-items-center">
                                <ez-icon
                                    icon="ezGolf-icon-plus-circle"
                                    size="14"
                                />
                                &nbsp; {{ $t('golf_tour_add_golfer') }}
                                </span>
                    </b-button>
                    <!-- <b-button
                      v-b-tooltip.hover
                      title="F3"
                      variant="outline-primary"
                      class="mr-1"
                    >
                    {{ $t('golf_starter_finish') }}
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      title="F4"
                      variant="outline-primary"
                      class="mr-1"
                    >
                    {{ $t("golf_common_view_detail" ) }}
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                    >
                      {{ $t('golf_starter_create_group') }}
                    </b-button>

                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                    >
                      {{ $t('golf_starter_update_group') }}
                    </b-button>

                    <b-button
                      variant="outline-primary"
                    >
                      <ez-icon
                        icon="ezGolf-icon-printer"
                        size="12"
                      />
                    </b-button> -->
                  </div>
                </template>

                <!-- <DxEditing
                  :allow-updating="true"
                  :allow-adding="true"
                  :allow-deleting="false"
                  :select-text-on-edit-start="false"
                  start-edit-action="dblClick"
                  mode="batch"
                >
                  <DxTexts
                    :edit-row="$t('golf_common_edit')"
                    :cancel-row-changes="$t('golf_common_cancel')"
                    :save-row-changes="$t('golf_common_save')"
                  />
                </DxEditing> -->
                <DxToolbar>
                  <DxItem
                    location="before"
                    template="beforeTemplate"
                  />
                  <DxItem
                    location="after"
                    template="afterTemplate"
                  />
                  <DxItem
                    widget="dxButton"
                    location="after"
                    :options="{width: 70}"
                    name="addRowButton"
                  />
                  <DxItem
                    widget="dxButton"
                    location="after"
                    :options="{width: 70}"
                    name="revertButton"
                  />
                  <DxItem
                    widget="dxButton"
                    location="after"
                    :options="{width: 70}"
                    name="saveButton"
                    cssClass='border-button'
                  />
                </DxToolbar>
                <!-- Bật cố định column -->
                <DxColumnFixing :enabled="true" />

                <!-- Cho phép group -->
                <DxGroupPanel :visible="false" />

                <!-- Phân trang -->
                <DxPaging :page-size="perPage" />
                <DxPager
                  :visible="true"
                  display-mode="full"
                  :show-info="true"
                />
                <template #edit-caddy="{ data: cellInfo }">
                  {{ cellInfo.value }}
                </template>
              </DxDataGrid>
              </b-overlay>
             
        </div>
        <b-modal
        id="modal-tour-scan-golfer"
        size="md"
        centered
        hide-header
        :no-close-on-backdrop="true"
      >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0 color-primary">
              {{ $t('golf_tour_search_golfer') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer color-primary"
              size="24"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
          <div class="form">
            <div class="form-block">
              <b-form-group :label="$t('golf_tour_input_vga_code')">
                <b-form-input v-model="dataSearchVgaCode" @change="searchMember"
                />
              </b-form-group>
            </div>
          </div>
          <div v-if="dataMemberSearch">
            <p>
              {{ $t('golf_tour_data_member_name') }}: {{ dataMemberSearch.MemberName }}
            </p>
            <p>
              {{ $t('golf_tour_data_vga_code') }}: {{ dataMemberSearch.Vgacode }}
            </p>
            <p>
              {{ $t('golf_tour_data_phone_number') }}: {{ dataMemberSearch.PhoneNumber }}
            </p>
            <p>
              {{ $t('golf_tour_data_email') }}: {{ dataMemberSearch.Email }}
            </p>
          </div>
        </b-card-body>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="md"
              class="float-right"
              @click="addMemberSearch"
            >
              {{ $t('golf_common_confirm') }}
            </b-button>
          </div>
        </template>
      </b-modal>
      <modalAddMember :tourId="tourId" :data="dataPropsAddMember" @event="handleEvent" />
    </b-card>
</template>
<script>
import dayjs from 'dayjs'
import { members } from '@/api/members'
import { commonServices } from '@/api/common-services'
import ExcelJS from "exceljs";
import readXlsxFile from 'read-excel-file';
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import {
  DxToolbar,
  DxItem,
  DxTexts,
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
  DxLoadPanel,
} from 'devextreme-vue/data-grid'
import 'devextreme/dist/css/dx.light.css'
import DxButton from 'devextreme-vue/button'
import { tour } from '@/api/tour'
import modalAddMember from './modal/modalAddMember.vue'
export default {
    components: {
        DxButton,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
        DxLoadPanel,
        DxToolbar,
        DxItem,
        DxTexts,
        modalAddMember
    },
    props: ['tourId'],
    data() {
        return {
            configTable: [
                {
                    caption: this.$t('golf_tour_table_vga_code'),
                    field: 'Vgacode',
                    isShow: true,
                    alignment: 'center',
                    width: '5%',
                    allowEditing: true,
                },
                {
                    caption: this.$t('golf_tour_table_fullname'),
                    field: 'FullName',
                    isShow: true,
                    alignment: 'left',
                    width: '15%',
                    allowEditing: true,
                },
                {
                    caption: this.$t('golf_tour_table_birthday'),
                    field: 'BirthDay',
                    isShow: true,
                    cellTemplate: 'birth-day',
                    alignment: 'center',
                    width: '7%',
                    allowEditing: true,
                    type: 'datetime',
                    editorOptions: {
                        displayFormat: 'dd/MM/yyyy',
                        useMaskBehavior: false,
                        type: 'datetime',
                    },
                    allowFiltering: false,
                },
                {
                    caption: this.$t('golf_tour_table_phonenumber'),
                    field: 'PhoneNumber',
                    isShow: true,
                    alignment: 'center',
                    width: '8%',
                    allowEditing: true,
                    allowFiltering: false,
                },
                {
                    caption: 'Email',
                    field: 'Email',
                    isShow: true,
                    alignment: 'center',
                    width: '8%',
                    allowEditing: true,
                    allowFiltering: false,
                },
                {
                    caption: this.$t('golf_tour_table_sex'),
                    field: 'SexCode',
                    isShow: true,
                    cellTemplate: 'sex-code',
                    alignment: 'center',
                    width: '5%',
                    allowEditing: true,
                    allowFiltering: false,
                },
                {
                    caption: this.$t('golf_tour_table_group'),
                    field: 'TournamentFlightName',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    allowEditing: true,
                },
                {
                    caption: this.$t('golf_tour_table_ta'),
                    field: 'ClientId',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    allowEditing: true,
                },
                {
                    caption: this.$t('golf_tour_table_hdc_index'),
                    field: 'HandicapIndex',
                    isShow: true,
                    alignment: 'center',
                    width: '5%',
                    allowEditing: true,
                },
                {
                    caption: this.$t('golf_tour_table_hdc_course'),
                    field: 'HandicapCourse',
                    isShow: true,
                    alignment: 'center',
                    width: '5%',
                    allowEditing: true,
                },
                {
                    caption: 'BDC',
                    field: 'BDC',
                    isShow: true,
                    alignment: 'center',
                    width: '7%',
                    allowEditing: true,
                },
                {
                    caption: 'Bagtag',
                    field: 'BagtagCode',
                    isShow: true,
                    alignment: 'center',
                    width: '5%',
                    allowEditing: true,
                },
                {
                    caption: this.$t('golf_tour_table_note'),
                    field: 'Note',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    allowEditing: true,
                },
                {
                    caption: '',
                    field: 'action',
                    isShow: true,
                    cellTemplate: 'action',
                    alignment: 'center',
                    allowEditing: false,
                    width: '5%',
                },
            ],
            currentPage: 1,
            perPage: 20,
            dataGridRef: 'tablePackage',
            optionPerPage: [10, 20, 50],
            listMember: [],
            dataSearchVgaCode: null,
            dataMemberSearch: null,
            currentLang: this.$i18n.locale,
            listSexCode: [],
            dataPropsAddMember: {
              type: null,
              data: null
            },
            isLoading: false
        }
    },
    methods: {
      rowUpdating(data) {
        
      },
      rowInserting(data) {
        
      },
      openModalScanGolfer() {
        this.$bvModal.show('modal-tour-scan-golfer')
      },
      hideModal() {
        this.$bvModal.hide('modal-tour-scan-golfer')
      },
      async searchMember() {
        const requestData = {
          MemberName: null,
          MemberCode: null,
          GolfClassId: null,
          CardTypeId: null,
          MemberType: null,
          CardCode: null,
          Vgacode: this.dataSearchVgaCode,
          InActive: null
        }
        await members.getListMember(requestData).then(res => {
          if(res.Status == 200) {
            if(res.Data.Member.length > 0) {
              this.dataMemberSearch = res.Data.Member[0]
            } else {
              this.showToast('error', this.$t('golf_tour_no_find_golfer'))
              this.dataMemberSearch = null
            }
          }
        })

      } ,
      async addMemberSearch() {
        if(!this.dataMemberSearch) {
          return this.showToast('error', this.$t('golf_tour_no_find_golfer'))
        }
        const body = {
          TournamentId: this.tourId,
          TournamentMember:[
            {
              FullName: this.dataMemberSearch.MemberName,
              ClientId: this.dataMemberSearch.CompanyId,
              Email: this.dataMemberSearch.Email,
              PhoneNumber: this.dataMemberSearch.PhoneNumber,
              SexCode: this.dataMemberSearch.SexCode,
              BirthDay: this.dataMemberSearch.Birthday,
              Vgacode: this.dataMemberSearch.Vgacode,
              Note: null,
              MemberId : this.dataMemberSearch.Id,
              HandicapIndex: null,
              HandicapCourse: null,
            }
          ]
        }
        await tour.api_TOUR_IGO01(body).then(res => {
          if(res.Status == 200) {
            this.dataMemberSearch = null
            this.hideModal()
            this.showResToast(res)
            this.getListGolfer()
            this.$emit('event', { type: 'after-add-golfer' })
          }
        })
      },
      async getListSexCode() {
        await commonServices
        .getCommon({ KeyGroup: 'SEX' })
        .then(res => {
          if (res) {
            this.listSexCode = res.Data.filter(x => x.LangId == this.currentLang)
          }
        })
      },
      async saveGolfer() {
        const listCreateMember = []
        this.listMember.forEach(x => {
          if(x.BirthDay) {
            x.BirthDay =  this.getUTC0(x.BirthDay)
          }
          listCreateMember.push({
            FullName: x.FullName ? x.FullName : null,
            ClientId: x.ClientId ? x.ClientId : null,
            Email: x.Email ? x.Email : null,
            PhoneNumber: x.PhoneNumber ? x.PhoneNumber : null,
            SexCode: x.SexCode ? x.SexCode : null,
            BirthDay: x.BirthDay ? x.BirthDay : null,
            Vgacode: x.Vgacode ? x.Vgacode : null,
            Note: x.Note ? x.Note : null,
            MemberId : x.MemberId ? x.MemberId : null,
            HandicapIndex: x.HandicapIndex ? Number(x.HandicapIndex) : null,
            HandicapCourse: x.HandicapCourse ? Number(x.HandicapCourse) : null,
          })
        })
        const body = {
          TournamentId: this.tourId,
          TournamentMember: listCreateMember
        }
        await tour.api_TOUR_IGO01(body).then(res => {
          if(res.Status == 200) {
            this.showResToast(res)
            this.getListGolfer()
            this.$emit('event', { type: 'after-add-golfer' })
          }
        })
      },
      getUTC0(day) {
        const timeZone = localStorage.getItem('timeZone')
        const offset = this.dayjs().utc().tz(timeZone)
            .utcOffset()

        let date
        date = this.dayjs(day).utc().tz(timeZone)
            .add(-(offset / 60), 'hour')
            .format('YYYY-MM-DDTHH:mm:ss')
            
        return `${date}Z`
      },
      handleEvent(result) {
        if (result.type === 'after-add-golfer') {
          this.getListGolfer()
        }
        if (result.type === 'after-update-golfer') {
          this.getListGolfer()
        }
      },
      openModalAddMember() {
        this.dataPropsAddMember = {
          type: 'ADD',
          data: {
            FullName: null,
            ClientId: null,
            Email: null,
            PhoneNumber: null,
            SexCode: null,
            BirthDay: null,
            Vgacode: null,
            Note: null,
            MemberId : null,
            HandicapIndex: null,
            HandicapCourse: null,
            FlightName: null
          }
        }
        this.$bvModal.show('modal-add-member')
      },
      openUpdateMember(dataMember) {
        this.dataPropsAddMember = {
          type: 'DETAIL',
          data: JSON.parse(JSON.stringify(dataMember))
        }
        this.$bvModal.show('modal-add-member')
      },
      async DeleteMember(Id) {
        const body = {
          TournamentMemberId: [Id]
        }
        await tour.api_TOUR_DGO01(body).then(res => {
          this.showResToast(res)
          if(res.Status == 200) {
              this.getListGolfer()
          }
        })
      },
      async getListGolfer() {
        this.isLoading = true
        const body = {
          TournamentId: this.tourId,
          TournamentGroupId: [],
          TournamentFlightId: [],
          VgaCode: [],
          KeyWord: null,
          isGetStoke: true
        }
        await tour.api_TOUR_RGO01(body).then(res => {
          if(res.Status == 200) {
            this.listMember = res.Data.TournamentMember
          }
          this.isLoading = false
        })
      },
      generateXLSX() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Format Member");
      const worksheetSexCode = workbook.addWorksheet("SexCode");
      const worksheetFormatDate = workbook.addWorksheet("FormatDate");

      worksheet.columns = [
          { header: "Họ tên", key: "FullName"},
          { header: "Mã Vga", key: "Vgacode"},
          { header: "Giới tính", key: "SexCode"},
          { header: "Ngày sinh", key: "BirthDay"},
          { header: "Số điện thoại", key: "PhoneNumber"},
          { header: "Email", key: "Email"},
          { header: "Handicap Index", key: "HandicapIndex"},
          { header: "Handicap Course", key: "HandicapCourse"},
          { header: "Mã nhóm", key: "FlightName"},
          { header: "Locker", key: "Locker"},
          { header: "Bagtag", key: "Bagtag"},
          { header: "Ghi chú", key: "Note"},
      ];
      worksheetFormatDate.columns = [
        { header: "Format Date", key: "key"},
      ];
      worksheetFormatDate.addRow({key: "'01/01/2001"})
      worksheetSexCode.columns = [
        { header: "Mã giới tính", key: "KeyCode"},
        { header: "Giới tính", key: "KeyValue"},
      ];
      this.listSexCode.forEach(x => {
        worksheetSexCode.addRow(x)
      })
      workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `TemplateMemberExport.xlsx`)
      })
    },
    async readFileExcel() {
      await readXlsxFile(this.$refs.choosefileExcel.files[0]).then(async (rows) => {
        var data = []
        var check = true
        var checkFormatDate = true
          rows.forEach((item,index) => {
            if(index != 0) {
              if(!item[0]) {
                check = false
              } else {
                data.push({
                  FullName: item[0] ? String(item[0]) : null,
                  ClientId: null,
                  Email: item[5] ? String(item[5]) : null,
                  PhoneNumber: item[4] ? String(item[4]) : null,
                  SexCode: item[2] ? String(item[2]) : null,
                  BirthDay: item[3] ? String(item[3]) : null,
                  Vgacode: item[1] ? String(item[1]) : null,
                  Note: item[11] ? String(item[11]) : null,
                  MemberId : item[14] ? Number(item[14]) : null,
                  HandicapIndex: item[6] ? Number(item[6]) : null,
                  HandicapCourse: item[7] ? Number(item[7]) : null,
                  FlightName: item[8] ? String(item[8]) : null,
                  LockerCode: item[9] ? String(item[9]) : null,
                  BagtagCode: item[10] ? String(item[10]) : null,
                  BDC: item[12] ? String(item[12]) : null,
                  TeeTimeId: item[13] ? Number(item[13]) : null,
                })
              }
              if(item[3]) {
                if(!this.validateForm(item[3])) {
                  checkFormatDate = false
                }
              }
            }
          });
          if(!check) {
            this.showToast('error', this.$t('golf_locker_please_input_excel'))
          } else if (!checkFormatDate) {
            this.showToast('error', this.$t('golf_locker_please_input_excel'))
          } else if (data.length == 0) {
            this.showToast('error', this.$t('golf_locker_please_input_excel'))
          } else {
            data.forEach(x => {
              if(x.BirthDay) {
                const BirthdayTemp = x.BirthDay.split('/')
                  x.BirthDay = this.convertToUTC0(new Date(BirthdayTemp[2],BirthdayTemp[1] - 1,BirthdayTemp[0],0,0,0))
                  }
            })
            const body = {
              TournamentId: this.tourId,
              TournamentMember: data
            }
            await tour.api_TOUR_IGO01(body).then(res => {
              if(res.Status == 200) {
                this.showResToast(res)
                this.getListGolfer()
                this.$emit('event', { type: 'after-add-golfer' })
              }
            })
          }
      });
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.value = ""
    },
    async sysMemberTour() {
      await tour.api_TOUR_UBK10({
        TournamentMemberIdBagTagLocker: this.listMember.map(x => x.Id),
        TournamentMemberIdMember: this.listMember.filter(x => x.BookingDetailId).map(y => y.Id)
      }).then(res => {
        if(res.Status == 200) {
          this.showResToast(res)
          this.getListGolfer()
        }
      })
    },
    triggerClickChooFiles() {
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.click();
    },
    convertToUTC0(date) {
      const timeZone = localStorage.getItem('timeZone')
      const offset = this.dayjs().utc().tz(timeZone).startOf('day')
        .utcOffset()
      return this.dayjs(date)
        .add(-offset, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
    },
    validateForm(input){
      var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
      if (input.match(reg)) {
        return true
      }
      else {
        return false
      }
    },
    },
    created() {
      this.getListSexCode()
      if(this.tourId) {
        this.getListGolfer()
      }
    },
    watch: {
      tourId(value) {
        if(value) {
          this.getListGolfer()
        }
      }
    }
}
</script>
<style lang="scss">
.table-starter {
    .dx-datagrid-rowsview .dx-row > td, .dx-datagrid-rowsview .dx-row > tr > td {
        overflow: inherit;
    }
    .dx-datagrid-rowsview {
        .dx-datagrid-table {
            
            tr {
                td {
                    &:hover {
                        cursor: pointer;
                        &:last-child {
                            cursor: inherit;
                        }
                        &:first-child {
                            .dx-template-wrapper::before {
                                font-size: 13px;
                            }
                        }
                    }
                    &:first-child {
                        // padding-left: 20px;
                        .dx-template-wrapper::before {
                            font-family: 'ezGolf' !important;
                            content: "\e958";
                            font-size: 0;
                            position: absolute;
                            left: 5px;
                            top: 9px;
                        }
                    }
                }
                &:hover {
                    td:first-child {
                        .dx-template-wrapper::before {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
    .dx-datagrid .dx-datagrid-pager .dx-pages .dx-page-indexes .dx-page {
        width: 35px;
        font-size: 16px;
    }
    .dx-datagrid .dx-datagrid-pager .dx-pages .dx-info {
        font-size: 16px;
    }
    td.dx-command-edit {
        .dx-link {
            border: 1px solid #114A9F;
            padding: 7px;
            color: #114A9F;
            border-radius: 0.358rem;
            text-decoration: none;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
        }
        .dx-link-save {
            background-color: #114A9F;
            color: #fff;
        }
    }
    .dx-datagrid-content {
        table{
            td{
                .dx-datebox-calendar {
                    .dx-texteditor-buttons-container {
                        display: none;
                    }
                }
            }
        }
    }
    .dx-checkbox-container {
        justify-content: center;
        padding: 0 15px;
    }
    .dx-toolbar-items-container {
        font-size: 12px;
        font-family: 'Roboto';
        .d-select-perPage {
            display: flex;
            justify-content: space-between;
            align-items: center;
            legend {
                width: 150px;
            }
        }
    }
    .dx-toolbar .dx-toolbar-before {
        left: 15px;
    }
    .dx-toolbar .dx-toolbar-after {
        right: 15px;
    }
    .dx-button-mode-contained {
        border-color: #114A9F;
    }
}
</style>